import Headroom from 'headroom.js';
export class Header {
    constructor(el) {
        var _a, _b, _c;
        this.headerToggle = () => {
            var _a, _b;
            document.documentElement.classList.toggle('header-open', (_a = this.burgerstate) === null || _a === void 0 ? void 0 : _a.checked);
            ((_b = this.burgerstate) === null || _b === void 0 ? void 0 : _b.checked) ? this.headroom.freeze() : this.headroom.unfreeze();
        };
        this.anchors = () => {
            const anchorLink = this.element.querySelectorAll('a[href^="#');
            anchorLink.forEach(element => {
                element.addEventListener('click', () => {
                    var _a, _b;
                    if ((_a = this.burgerstate) === null || _a === void 0 ? void 0 : _a.checked) {
                        (_b = this.burgerstate) === null || _b === void 0 ? void 0 : _b.click();
                    }
                });
            });
        };
        this.element = el;
        this.pageHero = document.querySelector('.venue-hero');
        this.burgerstate = this.element.querySelector('#burger-state');
        this.backdrop = this.element.querySelector('.js-header-backdrop');
        this.headroom = new Headroom(this.element, {
            offset: ((_a = this.pageHero) === null || _a === void 0 ? void 0 : _a.clientHeight) || 640 * 1.5,
            classes: {
                initial: 'header',
                pinned: 'header--pinned',
                unpinned: 'header--unpinned',
                top: 'header--top',
                notTop: 'header--not-top',
                bottom: 'header--bottom',
                notBottom: 'header--not-bottom',
                frozen: 'header--frozen',
            },
        });
        this.headroom.init();
        this.anchors();
        (_b = this.burgerstate) === null || _b === void 0 ? void 0 : _b.addEventListener('change', this.headerToggle, {
            passive: true,
        });
        (_c = this.backdrop) === null || _c === void 0 ? void 0 : _c.addEventListener('click', (ev) => {
            var _a, _b;
            ev.preventDefault();
            if ((_a = this.burgerstate) === null || _a === void 0 ? void 0 : _a.checked) {
                (_b = this.burgerstate) === null || _b === void 0 ? void 0 : _b.click();
            }
        });
        document.addEventListener('keydown', (ev) => {
            var _a, _b;
            if (((_a = this.burgerstate) === null || _a === void 0 ? void 0 : _a.checked) && ev.code === 'Escape' || ev.key === 'Escape' || ev.which === 27) {
                ev.preventDefault();
                (_b = this.burgerstate) === null || _b === void 0 ? void 0 : _b.click();
            }
        });
    }
}
