import { Cards } from './components/cards';
import Events from './components/events';
import { Header } from './components/header';
const docReady = (fn) => {
    // see if DOM is already available
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        // call on next available tick
        setTimeout(fn, 1);
    }
    else {
        document.addEventListener('DOMContentLoaded', fn);
    }
};
const init = () => {
    const header = document.querySelector('.header');
    if (header) {
        new Header(header);
        const langaugePicker = header.querySelector('.js-language-picker');
        langaugePicker === null || langaugePicker === void 0 ? void 0 : langaugePicker.addEventListener('change', () => {
            location.href = langaugePicker.value;
        });
    }
    const events = document.querySelectorAll('.js-events');
    if (events.length) {
        events.forEach((eventsEl) => new Events(eventsEl));
    }
    const cards = document.querySelectorAll('.js-cards');
    if (cards.length && CSS.supports('scroll-snap-type: x mandatory')) {
        cards.forEach(cardsEl => new Cards(cardsEl));
    }
};
docReady(init);
