import URLSearchParams from '@ungap/url-search-params';
class Events {
    constructor(el) {
        var _a;
        this.changeMonth = (delta) => {
            const newDate = new Date(this.currentDate);
            newDate.setDate(1);
            newDate.setMonth(newDate.getMonth() + delta);
            this.page = 1;
            this.getData(newDate, this.eventLocationUid, this.page).then((response) => {
                this.currentDate = newDate;
                if (this.controlsHeadline) {
                    this.controlsHeadline.innerText = response.ControlsHeadline;
                }
                const eventHtml = this.createEventListHtml(response.Events);
                this.eventListContainer.innerHTML = eventHtml;
                this.handlePagination(response.HasMorePages);
            });
        };
        this.loadNextPage = () => {
            this.page++;
            // use insertAdjacentHTML with beforeend
            this.getData(this.currentDate, this.eventLocationUid, this.page).then((response) => {
                const eventHtml = this.createEventListHtml(response.Events);
                this.eventListContainer.insertAdjacentHTML('beforeend', eventHtml);
                this.handlePagination(response.HasMorePages);
            });
        };
        this.createEventListHtml = (events) => {
            return events.reduce((acc, eventItem) => acc += this.createEventItemHtml(eventItem), '');
        };
        this.getData = (date, eventLocationUid, page) => {
            const params = new URLSearchParams();
            params.set('startDate', date.toISOString());
            params.set('eventLocationUid', eventLocationUid);
            params.set('page', page.toString());
            return fetch('/umbraco/MeyersFoodVenues/Events/GetEvents/?' + params, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            }).then((response) => {
                if (!response.ok) {
                    throw new Error('Der skete en fejl.');
                }
                return response.json();
            });
        };
        this.handlePagination = (hasMorePages) => {
            if (this.pagination) {
                this.pagination.style.display = hasMorePages ? 'block' : 'none';
            }
        };
        this.createEventItemHtml = (eventItem) => {
            const image = `<img
			srcset="${eventItem.Image.SourceSet}"
			src="${eventItem.Image.Source}"
			sizes="${eventItem.Image.Sizes}"
			alt="${eventItem.Image.AltText}"
			width="${eventItem.Image.Width}"
			height="${eventItem.Image.Height}"
			loading="lazy">`;
            const label = eventItem.Label
                ? `<span class="events__event-title-label">${eventItem.Label}</span>`
                : '';
            const priceIsFrom = eventItem.PriceIsFrom
                ? '<small class="events__event-pricetag__price-from">fra</small>'
                : '';
            const priceText = eventItem.PriceText != null
                ? `<text>&nbsp;</text><small>${eventItem.PriceText}</small>`
                : '';
            return `
		<a class="events__event ${eventItem.Link.ModifierClass}" role="listitem" aria-label="${eventItem.Link.Title}" href="${eventItem.Link.Url}" ${eventItem.Link.OpenInNewWindow ? 'target="blank" rel="noopener"' : ''}>
			<div class="events__event-date" aria-label="Dato">
				<span class="events__event-date-day">${eventItem.Date}</span>
				<span class="events__event-date-time">${eventItem.Time}</span>
			</div>
			<div class="events__event-thumbnail" aria-label="Thumbnail">
				${image}
			</div>
			<div class="events__event-title" aria-label="Titel">
				<span class="events__event-title-trumpet">${eventItem.Trumpet}</span>
				<span class="events__event-title-title">${eventItem.Link.Text}</span>
				${label}
			</div>
			<div class="events__event-pricetag" aria-label="Pris">
				${priceIsFrom}
				<span class="events__event-pricetag__price">
					${eventItem.Price}
					${priceText}
				</span>
			</div>
			<div class="events__event-link" aria-label="Link">
				<svg class="svg-icon">
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/meyers-venues/icons/svg-sprite.svg#arrow-angle" />
				</svg>
			</div>
		</a>`;
        };
        this.events = el;
        this.currentDate = new Date();
        this.page = 1;
        if (!el) {
            return;
        }
        const eventLocationUid = (_a = this.events.querySelector('input.js-events-location')) === null || _a === void 0 ? void 0 : _a.value;
        const eventsListContainer = this.events.querySelector('.js-events-eventlist');
        if (!eventLocationUid || !eventsListContainer) {
            return;
        }
        this.eventLocationUid = eventLocationUid;
        this.eventListContainer = eventsListContainer;
        const controls = this.events.querySelector('.js-events-controls');
        if (controls) {
            this.controlsHeadline = controls.querySelector('.js-events-controls-headline');
            this.controlsPrevious = controls.querySelector('button[direction="prev"]');
            this.controlsNext = controls.querySelector('button[direction="next"]');
            this.controlsPrevious.onclick = () => this.changeMonth(-1);
            this.controlsNext.onclick = () => this.changeMonth(1);
        }
        this.pagination = this.events.querySelector('.js-events-pagination');
        if (this.pagination) {
            const paginationClickElement = this.pagination.querySelector('a, button');
            if (paginationClickElement) {
                paginationClickElement.onclick = () => {
                    this.loadNextPage();
                    return false;
                };
            }
        }
    }
}
export default Events;
