export class Cards {
    constructor(el) {
        this.currentIndex = 0;
        this.elementIndices = {};
        this.elements = [];
        this.renderPagiation = () => {
            this.pagination.innerHTML = '';
            this.elements.forEach((_element, i) => {
                const dot = document.createElement('li');
                if (this.currentIndex === i) {
                    dot.classList.add('is-current');
                }
                this.pagination.appendChild(dot);
            });
        };
        if (!el) {
            return;
        }
        this.slider = el;
        this.pagination = this.slider.querySelector('.js-cards-pagination');
        const nodes = this.slider.querySelectorAll('a.card');
        for (let index = 0; index < nodes.length; index++) {
            this.elements.push(nodes[index]);
        }
        const observer = new IntersectionObserver((entries) => {
            // find the entry with the largest intersection ratio
            const activated = entries.reduce((max, entry) => {
                return (entry.intersectionRatio > max.intersectionRatio) ? entry : max;
            });
            if (activated.intersectionRatio >= 1) {
                this.currentIndex = this.elementIndices[activated.target.id];
                this.renderPagiation();
            }
        }, {
            root: this.slider, threshold: 1, rootMargin: '0px'
        });
        this.elements.forEach((element, i) => {
            this.elementIndices[element.id] = i;
            observer.observe(element);
        });
    }
}
